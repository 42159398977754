import script from "./LayoutNaked.vue?vue&type=script&setup=true&lang=js"
export * from "./LayoutNaked.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QParallax from 'quasar/src/components/parallax/QParallax.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QParallax,QLayout,QPageContainer,QFooter});
