<template>
  <router-view />
</template>
<script setup>
import { useQuasar } from "quasar";
import { onBeforeMount } from "vue";

const $q = useQuasar();
onBeforeMount(() => {
  $q.loadingBar.setDefaults({
    color: "purple",
    size: "5px",
    position: "bottom",
  });
});
</script>
