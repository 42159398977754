import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useAuthStore } from "@/stores/authStore";
import { useProfileStore } from "@/stores/profileStore";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async to => {
    const publicPages = ["/login", "/findid", "/findpw", "/404"];
    const requireAuth = !publicPages.includes(to.path);

    const auth = useAuthStore();
    const profile = useProfileStore();

    profile.setProfile();

    if (auth.checkToken()) {
        const currentAt = Number(new Date().getTime() / 1000); // 현재시간
        sessionStorage.setItem("latestActAt", currentAt);
    }

    if (requireAuth && !auth.isLoggedIn) {
        auth.redirectUrl = to.fullPath;
        return "/login";
    }
});

export default router;
